<template>
  <Spinner>
<!--    <BannerList />-->
    <BannerList2 />
  </Spinner>
  <Spinner>
    <CinemaList2 />
  </Spinner>
  <ClientOnly v-if="isAuthenticated">
    <Spinner>
      <PausesList />
    </Spinner>
    <Spinner>
      <ChannelFavList />
    </Spinner>
    <Spinner>
      <FavoriteList />
    </Spinner>
  </ClientOnly>
  <Spinner>
    <RecommendedList />
  </Spinner>
  <Spinner>
    <CollectionList />
  </Spinner>
</template>

<script setup>
import Spinner from '@/components/ui/Spinner.vue';
// const BannerList = defineAsyncComponent(() => import('@/components/ui/Banners/BannerList'));
const BannerList2 = defineAsyncComponent(() => import('@/components/ui/Banners/BannerList2'));
const CinemaList2 = defineAsyncComponent(() => import('@/components/ui/Cinemas/CinemaList2'));
const PausesList = defineAsyncComponent(() => import('@/components/ui/Pauses/PausesList.vue'));
const ChannelFavList = defineAsyncComponent(() => import('@/components/ui/Channels/ChannelFavList.vue'));
const FavoriteList = defineAsyncComponent(() => import('@/components/ui/FavoriteList.vue'));
const RecommendedList = defineAsyncComponent(() => import('@/components/ui/RecommendedList.vue'));
const CollectionList = defineAsyncComponent(() => import('@/components/ui/Collections/CollectionList.vue'));

const { isAuthenticated } = storeToRefs(useUserStore());
</script>
